import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1073.000000 1080.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" >


path d="M7068 9523 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7237 9449 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M7308 9413 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7370 9380 c19 -11 42 -19 50 -20 8 0 -2 9 -23 20 -20 11 -43 20 -49
20 -7 -1 3 -9 22 -20z"/>
<path d="M7440 9350 c0 -5 15 -16 33 -25 46 -21 139 -75 157 -90 8 -7 25 -16
36 -19 12 -4 29 -15 39 -26 10 -11 27 -20 38 -20 13 0 17 -5 13 -15 -3 -8 -2
-14 2 -13 4 1 20 -4 35 -10 16 -7 33 -12 38 -12 9 0 12 -11 10 -45 0 -11 2
-14 5 -7 8 17 22 15 52 -10 19 -15 21 -19 7 -13 -11 5 -37 8 -58 7 -56 -2 -75
10 -69 47 2 19 0 31 -7 31 -6 0 -11 -15 -11 -33 0 -25 6 -37 23 -46 14 -8 15
-10 3 -6 -19 5 -46 -10 -46 -26 0 -5 11 -14 25 -19 26 -10 35 -40 13 -40 -7 0
3 -14 22 -29 19 -16 39 -27 43 -24 5 3 26 -12 46 -33 21 -22 66 -61 102 -89
35 -27 66 -52 69 -55 3 -4 34 -30 70 -59 88 -73 355 -338 432 -431 35 -41 77
-91 95 -110 18 -19 33 -38 33 -42 0 -4 6 -13 14 -20 54 -54 253 -350 355 -528
39 -68 178 -346 210 -420 111 -255 224 -626 270 -885 37 -201 40 -225 55 -380
40 -405 26 -775 -45 -1174 -46 -256 -135 -567 -233 -815 -296 -746 -797 -1392
-1446 -1867 -539 -395 -1187 -664 -1840 -764 -58 -8 -132 -20 -165 -25 -33 -5
-162 -15 -286 -21 -572 -31 -1139 56 -1689 257 -69 26 -138 52 -155 59 -344
152 -507 241 -768 419 -184 126 -263 184 -315 233 -7 7 -34 29 -59 50 -26 21
-86 75 -133 119 -47 45 -97 93 -113 106 -15 14 -27 27 -27 30 0 3 -35 40 -78
84 -42 43 -97 103 -122 131 -32 38 -50 51 -62 47 -10 -3 -18 -1 -18 5 0 6 5
11 11 11 6 0 9 4 7 9 -4 11 -74 96 -104 128 -12 12 -24 28 -27 35 -3 8 -6 6
-6 -6 -1 -10 -9 -25 -18 -32 -17 -13 -17 -14 0 -8 18 6 24 -10 7 -21 -5 -3
-15 -15 -20 -25 -8 -16 -8 -18 5 -14 8 3 12 10 9 15 -3 5 2 9 12 9 12 0 15 6
12 25 -4 17 -1 25 8 25 8 0 14 -9 14 -19 0 -11 5 -23 10 -26 18 -11 10 -25
-15 -25 -23 0 -25 -3 -25 -52 0 -28 -3 -48 -7 -44 -5 4 -8 20 -8 34 -1 24 -5
27 -37 28 -35 1 -68 22 -68 43 0 5 -8 16 -17 23 -19 14 -38 40 -52 74 -6 12
-18 28 -28 35 -10 8 -27 31 -37 52 -11 20 -23 37 -28 37 -4 0 -8 6 -8 14 0 8
-6 21 -13 28 -14 14 -70 107 -99 163 -10 18 -22 36 -28 40 -32 20 184 -330
278 -450 4 -5 23 -32 42 -60 19 -27 38 -52 41 -55 3 -3 27 -32 54 -65 145
-181 391 -439 530 -556 22 -19 42 -36 45 -40 4 -4 103 -85 150 -122 623 -495
1406 -819 2217 -917 216 -27 605 -37 813 -21 260 19 481 53 760 115 177 40
480 142 705 238 209 89 592 292 603 320 2 4 8 8 13 8 12 0 173 109 290 197
353 263 670 586 925 938 52 72 97 135 101 140 19 25 63 95 63 100 0 4 11 21
24 38 30 40 160 276 221 403 427 883 549 1872 350 2838 -128 624 -407 1247
-778 1741 -68 89 -187 237 -198 245 -4 3 -22 23 -39 45 -101 129 -375 386
-625 588 -32 26 -247 177 -252 177 -3 0 -26 15 -52 33 -102 74 -331 203 -331
187z m393 -397 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m84 1
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-5997 -6275 c0 -6 -4
-7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m60 -59 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M7718 9123 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7701 9011 c21 -29 59 -48 59 -30 0 5 -7 9 -15 9 -9 0 -19 8 -22 18
-7 18 -22 32 -36 32 -4 0 2 -13 14 -29z"/>
<path d="M5160 8490 c-157 -11 -399 -51 -550 -91 -315 -82 -643 -225 -681
-296 -7 -13 -18 -23 -25 -23 -6 0 -14 -8 -18 -17 -6 -19 -27 -55 -37 -63 -17
-14 -124 -190 -177 -290 -144 -272 -241 -587 -272 -882 -12 -119 -12 -416 0
-535 35 -329 148 -675 319 -976 33 -59 156 -239 180 -264 12 -12 21 -27 21
-32 0 -11 5 -16 136 -156 46 -49 84 -96 84 -102 0 -21 -20 -15 -29 9 -5 14
-10 17 -15 10 -7 -12 27 -52 44 -52 5 0 13 7 18 16 7 12 11 13 16 5 4 -7 2
-19 -5 -27 -10 -12 -9 -13 5 -8 11 4 16 1 16 -10 0 -9 7 -16 17 -16 14 0 14 2
-2 20 -10 11 -16 22 -13 25 3 3 939 5 2081 5 1964 0 2076 1 2081 18 8 26 43
235 58 342 19 145 16 556 -6 710 -26 189 -53 321 -96 472 -125 439 -362 866
-665 1197 -305 333 -648 577 -1081 768 -172 75 -248 103 -394 143 -309 84
-690 122 -1010 100z m490 -1494 c360 -73 689 -259 905 -512 107 -127 232 -319
223 -345 -2 -7 -498 -12 -1416 -16 -1359 -6 -1412 -5 -1412 12 0 23 48 108
108 195 229 328 557 554 936 645 56 14 136 29 177 35 102 12 388 4 479 -14z"/>
<path d="M4161 4678 c1 -7 1 -343 0 -745 -1 -620 -3 -733 -15 -733 -9 0 -15
-14 -18 -39 -3 -38 -2 -40 29 -46 17 -3 43 -3 57 1 27 6 37 -9 14 -20 -19 -8
128 3 192 16 94 17 282 59 340 75 65 18 286 90 315 102 71 31 100 44 180 80
50 23 98 46 108 52 10 5 44 24 75 40 99 54 305 182 330 206 7 7 16 13 20 13 4
0 21 13 38 28 18 15 42 34 54 42 115 80 443 391 420 398 -5 2 -69 -4 -142 -14
-161 -21 -498 -24 -638 -6 -217 29 -493 102 -635 167 -27 13 -78 35 -112 49
-59 25 -153 75 -218 117 -16 10 -37 22 -45 26 -8 3 -30 17 -47 31 -19 14 -40
22 -49 18 -10 -4 -14 -1 -12 6 6 16 -75 78 -101 78 -24 0 -36 16 -19 23 7 3 2
6 -12 6 -14 1 -40 10 -58 21 -37 23 -54 25 -51 8z"/>
<path d="M7083 4163 c-244 -2 -443 -7 -443 -12 0 -4 -12 -7 -27 -6 -43 3 -53
-5 -53 -36 0 -25 -5 -30 -30 -35 l-30 -6 0 -634 0 -634 23 0 22 0 -3 635 c-1
441 1 635 8 635 8 0 10 -205 10 -669 -1 -478 1 -675 9 -690 12 -22 71 -31 71
-11 0 6 -8 10 -17 10 -9 0 -14 2 -11 5 3 4 18 3 33 -1 26 -7 27 -8 10 -20 -30
-22 -11 -25 30 -5 25 12 34 20 25 23 -8 3 -7 4 3 2 19 -3 217 94 247 121 9 8
19 15 23 15 3 0 57 34 119 77 169 114 297 221 438 364 104 107 158 167 253
284 108 133 358 560 342 585 -4 6 -117 9 -308 8 -166 -2 -501 -4 -744 -5z"/>
<path d="M6305 4110 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M1381 3484 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2668 3440 c-32 -22 -79 -55 -105 -73 -27 -18 -79 -55 -118 -82 -187
-129 -225 -157 -225 -166 0 -19 131 -199 145 -199 8 0 27 11 42 24 l28 24 -43
62 c-23 34 -40 66 -36 71 8 13 121 89 132 89 5 0 22 -18 37 -40 l27 -40 30 18
c50 29 52 38 24 75 -14 19 -26 39 -26 45 0 11 106 92 121 92 4 0 24 -27 45
-60 22 -33 43 -60 48 -60 19 0 77 43 73 54 -3 6 -29 45 -58 87 -30 41 -61 85
-69 97 l-15 22 -57 -40z"/>
<path d="M1390 3458 c0 -3 12 -26 26 -49 14 -24 23 -36 20 -26 -3 10 -6 22 -6
26 0 4 -9 18 -20 31 -11 13 -20 21 -20 18z"/>
<path d="M1456 3335 c24 -39 24 -40 24 -28 0 6 -9 21 -21 34 l-20 24 17 -30z"/>
<path d="M2900 3202 c0 -13 -140 -269 -153 -280 -6 -6 -73 -33 -147 -62 -74
-28 -139 -55 -144 -61 -6 -5 2 -22 23 -43 l33 -34 83 33 c46 18 86 31 88 28 3
-2 -13 -37 -34 -77 -21 -41 -39 -80 -39 -88 0 -18 57 -74 67 -65 3 4 39 69 78
144 l72 137 154 59 c84 32 154 59 156 60 1 2 -13 20 -31 41 l-32 39 -83 -31
c-46 -18 -87 -32 -92 -32 -5 0 11 37 35 83 l45 83 -30 37 c-29 35 -49 47 -49
29z"/>
<path d="M4134 3095 c-17 -13 -17 -14 -2 -15 9 0 20 5 23 11 11 17 0 20 -21 4z"/>
<path d="M1725 2950 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M1752 2920 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3165 2867 c-15 -17 -58 -66 -95 -107 -199 -223 -279 -316 -280 -327
0 -6 14 -23 32 -38 l32 -27 50 59 c28 32 65 73 82 92 l31 34 45 -32 c84 -61
155 -48 230 43 95 113 90 171 -24 275 -71 65 -72 66 -103 28z m72 -129 c13
-12 23 -31 23 -43 0 -28 -76 -115 -100 -115 -18 0 -70 36 -70 49 0 9 109 131
118 131 3 0 16 -10 29 -22z"/>
<path d="M1830 2877 c0 -1 4 -17 9 -35 6 -20 6 -32 0 -32 -5 0 -9 -5 -9 -11 0
-8 4 -9 13 0 8 6 18 7 23 3 4 -4 2 1 -5 10 -7 10 -11 29 -9 43 4 17 1 25 -8
25 -8 0 -14 -1 -14 -3z"/>
<path d="M6559 2689 c-1 -8 -1 -21 0 -30 1 -10 -13 -20 -39 -29 -22 -7 -40
-17 -40 -21 0 -5 21 0 47 10 51 20 72 51 47 71 -11 10 -14 10 -15 -1z"/>
<path d="M6595 2669 c-4 -6 -5 -12 -2 -15 7 -7 37 7 37 17 0 13 -27 11 -35 -2z"/>
<path d="M3451 2619 c-13 -18 -57 -80 -96 -138 -39 -58 -106 -155 -148 -215
-43 -60 -77 -114 -77 -120 0 -11 69 -61 75 -54 1 2 33 47 69 101 37 53 71 97
76 97 4 0 13 -6 19 -14 8 -10 0 -38 -34 -120 -25 -60 -45 -113 -45 -119 0 -11
69 -62 75 -55 1 2 25 57 53 123 28 66 57 123 64 126 77 36 91 47 133 108 57
84 64 117 36 173 -21 41 -120 124 -161 135 -9 2 -25 -9 -39 -28z m93 -122 c23
-18 28 -29 23 -48 -11 -41 -67 -109 -91 -109 -24 0 -66 26 -66 41 0 8 52 88
83 127 13 16 18 16 51 -11z"/>
<path d="M6450 2619 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3787 2433 c-40 -67 -277 -521 -277 -531 0 -10 199 -122 216 -122 10
0 50 78 43 84 -2 2 -32 18 -66 35 -35 17 -63 36 -63 42 0 5 15 39 33 74 34 68
53 79 91 50 32 -25 55 -18 73 21 20 40 20 39 -45 76 l-30 16 36 70 36 70 70
-35 c75 -38 80 -37 104 19 11 26 9 28 -51 61 -133 74 -161 85 -170 70z"/>
<path d="M4105 2248 c-33 -19 -53 -47 -75 -106 -29 -76 -16 -128 53 -216 36
-45 39 -92 11 -151 -13 -28 -22 -35 -45 -35 -19 0 -34 8 -44 24 -14 22 -14 28
4 75 l21 51 -36 15 c-20 8 -41 15 -46 15 -15 0 -48 -90 -48 -133 0 -57 29
-100 85 -127 83 -40 149 -14 198 81 24 46 28 63 25 116 -3 55 -9 70 -52 132
-43 62 -48 74 -42 106 4 20 16 45 26 55 16 16 24 18 51 8 31 -11 31 -11 25
-67 l-7 -57 34 -12 c46 -17 46 -16 63 23 33 79 5 162 -66 197 -40 20 -105 23
-135 6z"/>
<path d="M4454 2121 c-56 -34 -88 -142 -65 -214 7 -19 34 -60 61 -91 59 -68
67 -93 48 -155 -12 -40 -18 -46 -45 -49 -40 -4 -67 22 -59 57 21 91 22 89 -20
100 -22 6 -42 11 -45 11 -11 0 -29 -86 -29 -136 0 -42 4 -51 40 -85 51 -48
111 -60 167 -33 53 27 93 106 93 186 0 70 -8 87 -71 155 -43 46 -49 57 -49 96
0 29 7 53 19 68 16 20 23 22 47 13 27 -9 29 -13 28 -66 l-1 -55 43 -10 44 -10
10 36 c16 56 12 95 -13 132 -43 65 -139 89 -203 50z"/>
<path d="M5976 2073 c-4 -4 -15 -8 -23 -8 -11 0 -17 -12 -19 -40 -5 -68 8
-345 16 -345 5 0 7 55 6 123 -1 67 0 103 2 80 2 -24 7 -43 13 -43 5 0 10 19
11 43 l1 42 7 -45 7 -45 1 38 c2 44 17 41 28 -6 7 -30 8 -29 20 31 9 43 10 71
4 88 -6 13 -15 36 -21 51 -8 22 -12 25 -20 13 -8 -11 -9 -10 -4 8 6 22 -12 32
-29 15z m64 -89 c10 -24 3 -84 -9 -84 -11 0 -31 87 -25 105 8 19 23 10 34 -21z"/>
<path d="M6020 1960 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4756 1811 c-21 -142 -38 -281 -39 -307 l-2 -49 85 -11 c175 -23 197
3 234 259 31 213 32 242 6 284 -24 40 -58 58 -141 72 -115 20 -100 46 -143
-248z m203 134 c13 -12 12 -37 -9 -192 -29 -227 -28 -223 -84 -223 -25 0 -47
3 -49 8 -3 4 -1 32 3 62 26 181 51 347 56 359 5 17 62 8 83 -14z"/>
<path d="M5665 2033 c-16 -1 -58 -6 -92 -9 l-63 -6 0 -38 0 -39 52 -3 53 -3
17 -215 c25 -314 22 -300 64 -300 l34 0 -1 63 c0 34 -8 151 -17 260 -11 126
-14 201 -8 207 6 6 27 10 48 10 38 0 38 0 38 40 l0 40 -47 -1 c-27 -1 -61 -4
-78 -6z"/>
<path d="M5265 1998 c-3 -13 -23 -104 -45 -203 -22 -99 -51 -226 -63 -283 -13
-56 -22 -104 -20 -105 1 -2 22 -1 45 2 40 6 43 8 51 48 9 46 16 50 88 45 47
-4 49 -5 55 -40 3 -21 7 -43 9 -49 4 -13 66 -18 77 -8 3 3 1 27 -3 53 -34 187
-81 453 -85 482 -12 82 -11 80 -59 80 -38 0 -45 -3 -50 -22z m68 -278 c10 -54
17 -104 17 -110 0 -5 -21 -10 -46 -10 -33 0 -45 4 -41 13 2 6 12 56 22 110 10
53 22 97 25 97 4 0 14 -45 23 -100z"/>
<path d="M6050 1869 c0 -13 5 -31 10 -39 7 -11 8 -5 4 20 -8 43 -14 51 -14 19z"/>
<path d="M5981 1774 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6095 1660 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5938 1503 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5985 1470 c-16 -7 -17 -9 -3 -9 9 -1 20 4 23 9 7 11 7 11 -20 0z"/>
<path d="M0 35 l0 -35 5365 0 5365 0 0 35 0 35 -5365 0 -5365 0 0 -35z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
